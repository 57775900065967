// Generated by Framer (de9b242)

import { addFonts, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sXDgMaXPB"];

const variantClassNames = {sXDgMaXPB: "framer-v-54dshq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "sXDgMaXPB", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sXDgMaXPB", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WW95z", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-54dshq", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sXDgMaXPB"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p>Google</motion.p></React.Fragment>} className={"framer-hty5ux"} layoutDependency={layoutDependency} layoutId={"Jbhy7HAoP"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WW95z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WW95z .framer-t4n58v { display: block; }", ".framer-WW95z .framer-54dshq { height: 96px; position: relative; width: 306px; }", ".framer-WW95z .framer-hty5ux { flex: none; height: auto; left: 50%; position: absolute; top: 49%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 306
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerSg9OGM1ho: React.ComponentType<Props> = withCSS(Component, css, "framer-WW95z") as typeof Component;
export default FramerSg9OGM1ho;

FramerSg9OGM1ho.displayName = "Social";

FramerSg9OGM1ho.defaultProps = {height: 96, width: 306};

addFonts(FramerSg9OGM1ho, [])